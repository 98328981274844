import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

import {
    Accordion,
    Alert,
    Button,
    Col,
    Container,
    Figure,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { QuestionCircleFill } from "react-bootstrap-icons";
import { MatchCard } from "./matchCard/MatchCard";
import { LogParser, Match } from "@austenke/due-process-stat-parser";

function App() {

    const [fileName, setFilename] = useState('');
    const [matches, setMatches] = React.useState<Match[]>([]);
    const [fileDataCache, setFileDataCache] = React.useState<string>('');
    const [playerLogFileHandle, setPlayerLogFileHandle] = React.useState<FileSystemFileHandle | null>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (playerLogFileHandle) {
                const reader = new FileReader();
                reader.onload = function () {
                    var res = (reader.result as string);
                    LogParser.parseFileData(res, fileName, handleParse);
                };
                playerLogFileHandle.getFile().then(file => reader.readAsText(file));
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [playerLogFileHandle, fileName, fileDataCache]);

    function handleUpload(files: File[]) {
        LogParser.parseFile(files[0], handleParse);
    }

    function handleParse(fileName: string, matches: Match[]) {
        setMatches(matches);
        setFilename(fileName);
    }

    async function enableDynamicFileLoad() {
        var [filehandle] = await window.showOpenFilePicker({
            types: [
                {
                    description: 'Player.log',
                    accept: {
                        'text/plain': ['.log']
                    },
                }
            ],
        });

        const file = await filehandle.getFile();
        setFileDataCache(await file.text());
        setPlayerLogFileHandle(filehandle);
        LogParser.parseFileData(await file.text(), fileName, handleParse);
        setFilename(file.name);
    }

    return (
        <div className="App" style={{ height: '100%' }}>
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundImage: 'url(bg.jpg)', backgroundSize: 'cover', overflowY: 'scroll' }}>
                {matches.length === 0 &&
                    <Container>
                        <Row className="justify-content-center">
                            <h1 style={{ color: 'white', fontFamily: 'Oswald', textShadow: '0px 0px 20px #000' }}>Due Process Stats Tool</h1>
                        </Row>
                        <Row>
                            <Col className="justify-content-center">
                                <Dropzone onDrop={acceptedFiles => handleUpload(acceptedFiles)}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Figure className="fileUploader p-2 m-0" style={{
                                                border: '1px solid #5d2d2d80',
                                                borderRadius: '15px',
                                                cursor: 'pointer'
                                            }}>
                                                <Figure.Image width={180} height={180} src="pose.png" />
                                                <Figure.Caption style={{ color: '#000000' }}>Drag and drop <b>Player.log</b> file, or click to select</Figure.Caption>
                                            </Figure>
                                        </div>
                                    )}
                                </Dropzone>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-1">
                            <Button className="fileUploader p-2 m-0" variant="dark" onClick={() => enableDynamicFileLoad()}>
                                Or select a file to automatically reload
                            </Button>
                        </Row>
                        <Row className="justify-content-center mt-1">
                            <h6 style={{ color: 'white', fontFamily: 'Oswald', textShadow: '0px 0px 20px #000' }}>
                                If you run into issues, ping @Index
                            </h6>
                        </Row>
                        {matches.length === 0 && fileName &&
                            <Row className="justify-content-center mt-1">
                                <Alert variant='danger'>
                                    <div className="d-flex align-items-center justify-content-center">
                                        File '{fileName}' does not contain any matches
                                        <OverlayTrigger placement={'top'}
                                            overlay={
                                                <Tooltip id="errorTip">
                                                    A new log file is generated each time you start the game.
                                                    Have you played a match yet this session? <br /><br />
                                                    If this error persists, message @Index
                                                </Tooltip>
                                            }>
                                            <QuestionCircleFill className="ml-1" />
                                        </OverlayTrigger>
                                    </div>
                                </Alert>
                            </Row>
                        }
                    </Container>
                }
                {matches.length > 0 && fileName &&
                    <Accordion style={{ height: '100%', width: '90%' }} defaultActiveKey={matches[matches.length - 1].matchId.toString()}>
                        <div style={{ marginTop: '5%', marginBottom: '5%' }}>
                            <h2 style={{ color: 'white', fontFamily: 'Oswald', textShadow: '0px 0px 20px #000' }}>{fileName}</h2>
                        </div>
                        {matches.map(match => <MatchCard key={match.matchId} match={match} fileName={fileName} />)}
                    </Accordion>
                }
            </div>
        </div>
    );
}

export default App;
